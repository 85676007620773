@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap");

/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */
.App{
  /* background-image: url("./Assets/images/Banner.png"); */
  /* background-image: linear-gradient(to bottom right, black, gray),url("./Assets/images/Banner.png"); */
  /* background-repeat: no-repeat;
  background-position:center ;
  background-size: cover;
  height:100vh;
  color:black; */
}


.container {
  background-image: url("./Assets/images/Banner.png");
  background-repeat: no-repeat;
  background-position:center ;
  background-size: cover;
  height:74vh;
  color:black;
  max-width:100%;
  padding-left:4rem;
  padding-right:4rem;
}

article h1 {
  margin: 1rem 0;
}
.box{
  display:flex;
  align-items: flex-start;
}
.box-text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:60vw;
}

.box-text-content h3{
  margin-top:70px;
}
.box-text-content h1{
  margin-top:-25px;
}
.box-text-content p{
  margin-top:-25px;
}