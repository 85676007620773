.footer{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding:50px;
}
.footer-box{
    display:flex;
    align-items: center;
}